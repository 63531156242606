document.addEventListener('DOMContentLoaded', function () {
    const selectElement = document.querySelector('.custom-select select');
    const selected = document.createElement('div');
    selected.className = 'select-selected';
    selected.innerHTML = getDisplayValue(selectElement.options[selectElement.selectedIndex]);
    selectElement.parentNode.appendChild(selected);

    const itemsDiv = document.createElement('div');
    itemsDiv.className = 'select-items';
    for (let i = 0; i < selectElement.options.length; i++) {
        const itemDiv = document.createElement('div');
        itemDiv.innerHTML = getDisplayValue(selectElement.options[i]);
        itemDiv.addEventListener('click', function () {
            selectElement.selectedIndex = i;
            selected.innerHTML = this.innerHTML;
            itemsDiv.querySelectorAll('.same-as-selected').forEach(el => el.classList.remove('same-as-selected'));
            this.classList.add('same-as-selected');
            //itemsDiv.style.display = 'none';
            selected.classList.remove('select-arrow-active');
        });
        itemsDiv.appendChild(itemDiv);
    }
    selectElement.parentNode.appendChild(itemsDiv);

    selected.addEventListener('click', function (e) {
        e.stopPropagation();
        //closeAllSelect(this);
        //itemsDiv.style.display = itemsDiv.style.display === 'none' ? 'block' : 'none';
        this.classList.toggle('select-arrow-active');
    });

    // function closeAllSelect(elmnt) {
    //     const items = document.querySelectorAll('.select-items');
    //     const selected = document.querySelectorAll('.select-selected');
    //     for (let i = 0; i < selected.length; i++) {
    //         if (elmnt !== selected[i]) {
    //             selected[i].classList.remove('select-arrow-active');
    //         }
    //     }
    //     for (let i = 0; i < items.length; i++) {
    //         if (items[i].style.display !== 'none') {
    //             items[i].style.display = 'none';
    //         }
    //     }
    // }
  
    function getDisplayValue(option) {
      const icon = option.getAttribute('data-icon');
      let picto = "";
      switch (icon) {
        case 'svgDown':
            picto = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>';
            break;
      
        default:
            picto = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2 160 448c0 17.7 14.3 32 32 32s32-14.3 32-32l0-306.7L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"/></svg>';
            break;
      }
      
      return icon ? `${option.innerText} ${picto}` : option.innerText;
    }
  
    //document.addEventListener('click', closeAllSelect);
  });